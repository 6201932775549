import * as React from "react"
import { useState } from "react"
import { Frame, addPropertyControls, ControlType } from "framer"

export function PriceCalculator(props) {
    const {
        pricePerUnit,
        inputPlaceholder,
        inputFontSize,
        labelFontSize,
        labelFontWeight,
        padding,
        textAlign,
        marginBottom,
        inputPadding,
    } = props

    // State to store the amount entered by the user
    const [amount, setAmount] = useState("")

    // Function to handle the input change
    const handleInputChange = (event) => {
        const value = event.target.value
        // Ensure only numbers are entered
        if (!isNaN(value)) {
            setAmount(value)
        }
    }

    // Calculate the total price
    const totalPrice = amount ? parseFloat(amount) * pricePerUnit : 0

    return (
        <Frame style={{ padding: padding, textAlign: textAlign }}>
            <input
                type="text"
                value={amount}
                onChange={handleInputChange}
                placeholder={inputPlaceholder}
                style={{
                    marginBottom: marginBottom,
                    padding: inputPadding,
                    fontSize: inputFontSize,
                }}
            />
            <div
                style={{ fontSize: labelFontSize, fontWeight: labelFontWeight }}
            >
                Total Price: ${totalPrice}
            </div>
        </Frame>
    )
}

addPropertyControls(PriceCalculator, {
    pricePerUnit: {
        type: ControlType.Number,
        title: "Price per Unit",
        defaultValue: 89,
    },
    inputPlaceholder: {
        type: ControlType.String,
        title: "Placeholder",
        defaultValue: "Enter amount",
    },
    inputFontSize: {
        type: ControlType.Number,
        title: "Input Font Size",
        defaultValue: 16,
    },
    labelFontSize: {
        type: ControlType.Number,
        title: "Label Font Size",
        defaultValue: 20,
    },
    labelFontWeight: {
        type: ControlType.String,
        title: "Label Font Weight",
        defaultValue: "bold",
    },
    padding: {
        type: ControlType.String,
        title: "Padding",
        defaultValue: "20px",
    },
    textAlign: {
        type: ControlType.Enum,
        title: "Text Align",
        options: ["left", "center", "right"],
        optionTitles: ["Left", "Center", "Right"],
        defaultValue: "center",
    },
    marginBottom: {
        type: ControlType.String,
        title: "Margin Bottom",
        defaultValue: "20px",
    },
    inputPadding: {
        type: ControlType.String,
        title: "Input Padding",
        defaultValue: "10px",
    },
})
